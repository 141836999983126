import axios from '@/plugins/axios';

class ProductService {
  getProducts(data) {
    this.data = data;
    return axios().get('admin/products', { params: this.data }).then((response) => response.data);
  }

  getProduct(id) {
    this.id = id;
    return axios().get(`admin/products/${id}`).then((response) => response.data);
  }

  getAccountProducts(data) {
    this.data = data;
    return axios().get('products', { params: this.data }).then((response) => response.data);
  }

  createProduct(data) {
    this.data = data;
    return axios().post('admin/products', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  updateProduct(data) {
    this.data = data;
    return axios().put(`admin/products/${data.id}`, this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteProduct(id) {
    this.id = id;
    return axios().delete(`admin/products/${id}`).then((response) => response.data);
  }
}

export default new ProductService();
